import {
  GET_ORDER_COMPLETE,
  GET_ORDER_COMPLETE_SUCCESS,
  GET_ORDER_COMPLETE_FAILED,
  GET_ORDER_COMPLETE_RESET,
  SORTING_ORDER_COMPLETE,
  SET_TEXT_FILTER_ORDER_COMPLETE
} from "../actionTypes/ordersComplete"

const initialState = {
  data: [],
  meta: {
    total: 0,
    status: "",
    isLoading: true
  },
  filters: {},
  sorter: {
    dataType: "date",
    type: "DESC",
    sortBy: "order_date"
  }
}

export default (state = initialState, action) => {
  const {type, payload} = action

  switch (type) {
    case GET_ORDER_COMPLETE:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isLoading: true
        }
      }
    case GET_ORDER_COMPLETE_SUCCESS:
      return {
        ...state,
        data: payload.orders,
        meta: {
          ...state.meta,
          total: payload.total,
          status: "FETCHED",
          isLoading: false
        }
      }
    case GET_ORDER_COMPLETE_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FAILURE",
          isLoading: false
        }
      }
    case GET_ORDER_COMPLETE_RESET:
      return initialState
    case SORTING_ORDER_COMPLETE:
      return {
        ...state,
        sorter: payload
      }

    // filter
    case SET_TEXT_FILTER_ORDER_COMPLETE:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload
        },
        sorter: initialState.sorter
      }
    default:
      return state
  }
}
