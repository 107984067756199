import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import config from "../../config"
const successImg = `${config.bucketPath}/assets/signature/success.png`
const failedImg = `${config.bucketPath}/assets/signature/failed.png`

const ModalSignature = ({type, description}) => {
  return (
    <>
      <Wrapper>
        <div className="content">
          <div>
            <MainContent>
              <img
                src={type === "success" ? successImg : failedImg}
                style={{
                  maxWidth: "235px",
                  margin: "30px 0px"
                }}
              />
              <p>{description}</p>
            </MainContent>
          </div>
        </div>
      </Wrapper>
    </>
  )
}

ModalSignature.propTypes = {
  type: PropTypes.string,
  description: PropTypes.string
}

export default ModalSignature
// -- Layout
const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100vh;
  .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background: rgb(22, 22, 22);
    color: rgb(255, 255, 255);
    text-align: center;
  }
`

// Content
const MainContent = styled.div`
  width: 100%;
  text-align: left;
  padding: 0px 25px 15px 25px;
  position: relative;
  display: block;
  text-align: center;
  h4 {
    font-size: 35px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    margin-bottom: 30px;
  }
  p {
    padding: 0px 25px;
    color: rgb(165, 165, 165);
    font-size: 16px;
    margin-bottom: 100px;
  }
`
