import React, { Suspense, Fragment, lazy } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import Loadable from 'react-loadable'
import createHistory from 'history/createBrowserHistory'
// <!--meta name="viewport" content="width=device-width, initial-scale=1" /-->
import { Helmet } from 'react-helmet'
import '../../node_modules/font-awesome/scss/font-awesome.scss'

import { getSession } from '../utils/session'
import Loader from '../App/layout/Loader'
import ScrollToTop from '../App/layout/ScrollToTop'
import PublicRoute from './PublicRouter'
import ModalSignatureView from '../components/Modal/ModalSignature'

const LoginPage = lazy(() => import('../pages/auth/login'))
const ResetPasswordPage = lazy(() => import('../pages/auth/resetPassword'))
// Signature Component -----------
const SignaturePageStep1 = lazy(() => import('../pages/signature/step1'))
const SignaturePageStep2 = lazy(() => import('../pages/signature/step2'))
const NotFoundPage = lazy(() => import('../pages/errors/notFoundPage'))

const Finish = lazy(() => import('../pages/signature/finish'))

const AdminLayout = Loadable({
  loader: () => import('../App/layout/AdminLayout'),
  loading: Loader,
})

const history = createHistory()

const SignatureRouters = () => (
  <div className="application">
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
    <Switch>
      <PublicRoute path="/:params" component={SignaturePageStep1} noAuth exact />
      <PublicRoute path="/:id/sign" component={SignaturePageStep2} noAuth exact />
      <Route
        component={() => (
          <ModalSignatureView
            type="failed"
            description="Uppss.. Maaf, Halaman tidak dapat ditemukan"
          />
        )}
      />
      <PublicRoute path="/:id/request" component={Finish} noAuth exact />
    </Switch>
  </div>
)

const PrioritasRouters = ({ isAuthenticated }) => (
  <Switch>
    <PublicRoute isAuthenticated={isAuthenticated} path="/" component={LoginPage} exact />
    <PublicRoute
      isAuthenticated={isAuthenticated}
      path="/reset-password"
      component={ResetPasswordPage}
      exact
    />
    <PublicRoute
      isAuthenticated={isAuthenticated}
      path="/change-password"
      component={ResetPasswordPage}
      exact
    />
    <PublicRoute path="/404" component={NotFoundPage} noAuth exact />
    <AdminLayout isAuthenticated={isAuthenticated} />
  </Switch>
)

PrioritasRouters.propTypes = {
  isAuthenticated: PropTypes.bool,
}

const AppRouter = ({ isAuthenticated }) => {
  const isSignature = window.location.pathname === '/signature.html'
  return (
    <>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Router history={history} basename={isSignature ? 'signature.html#' : 'index.html#'}>
            {isSignature ? (
              <SignatureRouters />
            ) : (
              <PrioritasRouters isAuthenticated={isAuthenticated} />
            )}
          </Router>
        </Suspense>
      </ScrollToTop>
    </>
  )
}

const mapStateToProps = () => {
  const session = getSession('b-prio-auth')
  const now = new Date().getTime()
  return {
    isAuthenticated: session ? now < session.expired : false,
  }
}

AppRouter.propTypes = {
  isAuthenticated: PropTypes.bool,
}

export default connect(mapStateToProps)(AppRouter)
