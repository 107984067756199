import {
  GET_ADMIN_LIST,
  GET_ADMIN_LIST_SUCCESS,
  GET_ADMIN_LIST_FAILED,
  GET_ADMIN_DETAIL_SUCCESS,
  GET_ADMIN_DETAIL,
  GET_ADMIN_DETAIL_FAILED
} from "../actionTypes/admins"

const initialState = {
  list: [],
  detail: {},
  meta: {
    total: 0,
    isLoading: true,
    isError: false
  }
}

export default (state = initialState, action) => {
  const {type, payload} = action

  switch (type) {
    // list
    case GET_ADMIN_LIST:
      return {
        ...state,
        meta: {
          ...state.meta,
          isLoading: true,
          isError: false
        }
      }
    case GET_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        list: payload.admins,
        meta: {
          ...state.meta,
          total: payload.total,
          isLoading: false
        }
      }
    case GET_ADMIN_LIST_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          isLoading: false,
          isError: true
        }
      }

    // datail
    case GET_ADMIN_DETAIL:
      return {
        ...state,
        meta: {
          ...state.meta,
          isLoading: true,
          isError: false
        }
      }
    case GET_ADMIN_DETAIL_SUCCESS:
      return {
        ...state,
        detail: payload,
        meta: {
          ...state.meta,
          isLoading: false
        }
      }
    case GET_ADMIN_DETAIL_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          isError: true
        }
      }

    default:
      return state
  }
}
