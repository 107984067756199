import {
  GET_MANAGER_INVESTMENT_REQUEST,
  GET_MANAGER_INVESTMENT_SUCCESS,
  GET_MANAGER_INVESTMENT_FAILURE,
  SET_OPTIONS_MANAGER_INVESTMENT
} from "../actionTypes/managerInvestments"

const initialState = {
  data: {
    manager_investments: [],
    option_manager_investments: []
  },
  meta: {
    status: "",
    isLoading: true
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MANAGER_INVESTMENT_REQUEST:
      return {
        ...state,
        meta: {
          status: "LOADING",
          isLoading: true
        }
      }
    case GET_MANAGER_INVESTMENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          manager_investments: action.payload
        },
        meta: {
          status: "FETCHED",
          isLoading: false
        }
      }
    case SET_OPTIONS_MANAGER_INVESTMENT:
      return {
        ...state,
        data: {
          ...state.data,
          option_manager_investments: action.payload
        },
        meta: {
          status: "FETCHED",
          isLoading: false
        }
      }
    case GET_MANAGER_INVESTMENT_FAILURE:
      return {
        ...state,
        meta: {
          status: "FAILURE",
          isLoading: false
        }
      }
    default:
      return state
  }
}
