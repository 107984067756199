export const GET_DRAFT_INVESTOR_REQUEST = 'GET_DRAFT_INVESTOR_REQUEST'
export const GET_DRAFT_INVESTOR_SUCCESS = 'GET_DRAFT_INVESTOR_SUCCESS'
export const GET_DRAFT_INVESTOR_FAILURE = 'GET_DRAFT_INVESTOR_FAILURE'
export const GET_INVESTOR_REQUEST = 'GET_INVESTOR_REQUEST'
export const GET_INVESTOR_SUCCESS = 'GET_INVESTOR_SUCCESS'
export const GET_INVESTOR_FAILURE = 'GET_INVESTOR_FAILURE'
export const GET_INVESTOR_OPTION_SUCCESS = 'GET_INVESTOR_OPTION_SUCCESS'
export const GET_INVESTOR_DETAIL_SUCCESS = 'GET_INVESTOR_DETAIL_SUCCESS'
export const GET_DETAIL_INVESTOR = 'GET_DETAIL_INVESTOR'
export const GET_DETAIL_INVESTOR_DRAFT = 'GET_DETAIL_INVESTOR_DRAFT'
export const INVESTOR_DRAFT_INSTITUTION_SAVE = 'INVESTOR_DRAFT_INSTITUTION_SAVE'
export const INVESTOR_DRAFT_INSTITUTION_SAVE_FAILURE = 'INVESTOR_DRAFT_INSTITUTION_SAVE_FAILURE'
export const INVESTOR_DRAFT_INSTITUTION_SAVE_SUCCESS = 'INVESTOR_DRAFT_INSTITUTION_SAVE_SUCCESS'
export const INVESTOR_DRAFT_INSTITUTION_DETAIL_GET = 'INVESTOR_DRAFT_INSTITUTION_DETAIL_GET'
export const INVESTOR_DRAFT_INSTITUTION_DETAIL_GET_FAILURE =
  'INVESTOR_DRAFT_INSTITUTION_DETAIL_GET_FAILURE'
export const INVESTOR_DRAFT_INSTITUTION_DETAIL_GET_SUCCESS =
  'INVESTOR_DRAFT_INSTITUTION_DETAIL_GET_SUCCESS'
export const INVESTOR_DRAFT_DETAIL_RESET = 'INVESTOR_DRAFT_DETAIL_RESET'
