import {
  SWITCH_GET_LIST,
  SWITCH_GET_LIST_SUCCESS,
  SWITCH_GET_LIST_FAILED,
  SWITCH_SUBMIT_ORDER,
  SWITCH_SUBMIT_ORDER_SUCCESS,
  SWITCH_SUBMIT_ORDER_FAILED
} from "../actionTypes/switch"

const initialState = {
  data: {
    switching: []
  },
  meta: {
    isLoading: false,
    isError: false,
    isSubmit: false
  }
}

export default (state = initialState, action) => {
  const {type, payload} = action

  switch (type) {
    case SWITCH_GET_LIST:
      return {
        ...state,
        meta: {
          ...state.meta,
          isLoading: true,
          isError: false
        }
      }

    case SWITCH_GET_LIST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          switching: payload
        },
        meta: {
          isLoading: false
        }
      }

    case SWITCH_GET_LIST_FAILED:
      return {
        ...state,
        meta: {
          isLoading: false,
          isError: true
        }
      }

    case SWITCH_SUBMIT_ORDER:
      return {
        ...state,
        meta: {
          ...state.meta,
          isSubmit: true
        }
      }
    case SWITCH_SUBMIT_ORDER_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          isSubmit: false
        }
      }
    case SWITCH_SUBMIT_ORDER_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          isSubmit: false
        }
      }

    default:
      return state
  }
}
