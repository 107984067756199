export const GET_PORTFOLIO_AUM = "GET_PORTFOLIO_AUM"
export const GET_PORTFOLIO_AUM_SUCCESS = "GET_PORTFOLIO_AUM_SUCCESS"
export const GET_PORTFOLIO_AUM_FAILED = "GET_PORTFOLIO_AUM_FAILED"
export const GET_PORTFOLIO_LIST = "GET_PORTFOLIO_LIST"
export const GET_PORTFOLIO_LIST_SUCCESS = "GET_PORTFOLIO_LIST_SUCCESS"
export const GET_PORTFOLIO_LIST_FAILED = "GET_PORTFOLIO_LIST_FAILED"
export const GET_PORTFOLIO_TRANSACTION = "GET_PORTFOLIO_TRANSACTION"
export const GET_PORTFOLIO_TRANSACTION_SUCCESS =
  "GET_PORTFOLIO_TRANSACTION_SUCCESS"
export const GET_PORTFOLIO_TRANSACTION_FAILED =
  "GET_PORTFOLIO_TRANSACTION_FAILED"
export const GET_PORTFOLIO_DETAIL_REQUEST = "GET_PORTFOLIO_DETAIL_REQUEST"
export const GET_PORTFOLIO_DETAIL_SUCCESS = "GET_PORTFOLIO_DETAIL_SUCCESS"
export const GET_PORTFOLIO_DETAIL_FAILED = "GET_PORTFOLIO_DETAIL_FAILED"
export const SORTING_PORTFOLIO_LIST = "SORTING_PORTFOLIO_LIST"
export const SET_TEXT_FILTER_PORTFOLIO_LIST = "SET_TEXT_FILTER_PORTFOLIO_LIST"
export const SORTING_TRANSACTION = "SORTING_TRANSACTION"
export const FILTER_BY_TEXT = "FILTER_BY_TEXT"
export const FILTER_BY_DATE = "FILTER_BY_DATE"
export const FILTER_BY_TYPE = "FILTER_BY_TYPE"
export const SET_FILTER_TRANSACTION = "SET_FILTER_TRANSACTION"
export const SET_FILTER_PORTFOLIO = "SET_FILTER_PORTFOLIO"
