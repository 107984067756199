import {
  SUBSCRIPTION_ADD_TO_CART_REQUEST,
  SUBSCRIPTION_ADD_TO_CART_FAILURE,
  SUBSCRIPTION_ADD_TO_CART_SUCCESS,
  SUBSCRIPTION_LIST_CART_REQUEST,
  SUBSCRIPTION_LIST_CART_FAILURE,
  SUBSCRIPTION_LIST_CART_SUCCESS,
  SUBSCRIPTION_UPDATE_CART_REQUEST,
  SUBSCRIPTION_UPDATE_CART_SUCCESS,
  SUBSCRIPTION_UPDATE_CART_FAILURE,
  SUBSCRIPTION_SEND_SMS_FAILURE,
  SUBSCRIPTION_SEND_SMS_SUCCESS,
  SUBSCRIPTION_SEND_SMS_REQUEST,
  SUBSCRIPTION_SUBMIT_ORDER_FAILURE,
  SUBSCRIPTION_SUBMIT_ORDER_SUCCESS,
  SUBSCRIPTION_SUBMIT_ORDER_REQUEST
} from "../actionTypes/subscriptions"

const initialState = {
  data: {
    subscriptions: []
  },
  meta: {
    status: "",
    isLoading: true,
    isSubmit: false,
    isSendToken: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTION_SUBMIT_ORDER_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isSubmit: true
        }
      }
    case SUBSCRIPTION_SUBMIT_ORDER_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FETCHED",
          isSubmit: false
        }
      }
    case SUBSCRIPTION_SUBMIT_ORDER_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FAILURE",
          isSubmit: false
        }
      }
    case SUBSCRIPTION_SEND_SMS_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isSendToken: true
        }
      }
    case SUBSCRIPTION_SEND_SMS_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FETCHED",
          isSendToken: false
        }
      }
    case SUBSCRIPTION_SEND_SMS_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FAILURE",
          isSendToken: false
        }
      }
    case SUBSCRIPTION_LIST_CART_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isLoading: true
        }
      }
    case SUBSCRIPTION_LIST_CART_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          subscriptions: action.payload
        },
        meta: {
          ...state.meta,
          status: "FETCHED",
          isLoading: false
        }
      }
    case SUBSCRIPTION_LIST_CART_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FAILURE",
          isLoading: false
        }
      }
    case SUBSCRIPTION_ADD_TO_CART_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isSubmit: true
        }
      }
    case SUBSCRIPTION_ADD_TO_CART_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isSubmit: false
        }
      }
    case SUBSCRIPTION_ADD_TO_CART_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FAILURE",
          isSubmit: false
        }
      }
    case SUBSCRIPTION_UPDATE_CART_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isSubmit: true
        }
      }
    case SUBSCRIPTION_UPDATE_CART_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isSubmit: false
        }
      }
    case SUBSCRIPTION_UPDATE_CART_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FAILURE",
          isSubmit: false
        }
      }
    default:
      return state
  }
}
