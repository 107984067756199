import {
  GET_TOP_INVESTORS,
  GET_TOP_INVESTORS_SUCCESS,
  GET_TOP_INVESTORS_FAILED,
  //total
  GET_TOTAL_INVESTORS,
  GET_TOTAL_INVESTORS_SUCCESS,
  GET_TOTAL_INVESTORS_FAILED,
  GET_TOTAL_AUM,
  GET_TOTAL_AUM_SUCCESS,
  GET_TOTAL_AUM_FAILED,
  GET_TOTAL_REVENUE,
  GET_TOTAL_REVENUE_SUCCESS,
  GET_TOTAL_REVENUE_FAILED,
  GET_TOTAL_TRAILING_REVENUE,
  GET_TOTAL_TRAILING_REVENUE_SUCCESS,
  GET_TOTAL_TRAILING_REVENUE_FAILED,
  //chart
  GET_CHART_AUM,
  GET_CHART_AUM_SUCCESS,
  GET_CHART_AUM_FAILED,
  GET_CHART_REVENUE,
  GET_CHART_REVENUE_SUCCESS,
  GET_CHART_REVENUE_FAILED
} from "../actionTypes/dashboard"

const initialState = {
  topInvestors: {
    isLoading: true,
    error: false,
    data: []
  },
  totalInvestors: {
    isLoading: true,
    error: false,
    value: 0
  },
  totalAum: {
    isLoading: true,
    error: false,
    value: 0
  },
  totalRevenue: {
    isLoading: true,
    error: false,
    value: 0
  },
  totalTrailingRevenue: {
    isLoading: true,
    error: false,
    value: 0
  },
  chartAum: {
    isLoading: true,
    error: false,
    data: []
  },
  chartRevenue: {
    isLoading: true,
    error: false,
    data: []
  }
}

export default (state = initialState, action) => {
  const {type, payload} = action

  switch (type) {
  // TOP INVESTORS
  case GET_TOP_INVESTORS:
    return {
      ...state,
      topInvestors: {
        ...state.topInvestors,
        error: false,
        isLoading: true
      }
    }
  case GET_TOP_INVESTORS_SUCCESS:
    return {
      ...state,
      topInvestors: {
        isLoading: false,
        data: payload
      }
    }
  case GET_TOP_INVESTORS_FAILED:
    return {
      ...state,
      topInvestors: {
        ...state.topInvestors,
        isLoading: false,
        error: true
      }
    }

    // TOTAL INVESTORS
  case GET_TOTAL_INVESTORS:
    return {
      ...state,
      totalInvestors: {
        ...state.totalInvestors,
        isLoading: true
      }
    }
  case GET_TOTAL_INVESTORS_SUCCESS:
    return {
      ...state,
      totalInvestors: {
        ...state.totalInvestors,
        isLoading: false,
        error: false,
        value: payload
      }
    }
  case GET_TOTAL_INVESTORS_FAILED:
    return {
      ...state,
      totalInvestors: {
        ...state.totalInvestors,
        isLoading: false,
        error: true
      }
    }

    // TOTAL AUM
  case GET_TOTAL_AUM:
    return {
      ...state,
      totalAum: {
        ...state.totalAum,
        isLoading: true
      }
    }
  case GET_TOTAL_AUM_SUCCESS:
    return {
      ...state,
      totalAum: {
        ...state.totalAum,
        isLoading: false,
        error: false,
        value: payload.reduce((r, {total}) => r + parseFloat(total), 0)
      }
    }
  case GET_TOTAL_AUM_FAILED:
    return {
      ...state,
      totalAum: {
        ...state.totalAum,
        isLoading: false,
        error: true
      }
    }

    // TOTAL REVENUE
  case GET_TOTAL_REVENUE:
    return {
      ...state,
      totalRevenue: {
        ...state.totalRevenue,
        isLoading: true
      }
    }
  case GET_TOTAL_REVENUE_SUCCESS:
    return {
      ...state,
      totalRevenue: {
        ...state.totalRevenue,
        isLoading: false,
        error: false,
        value: payload
      }
    }
  case GET_TOTAL_REVENUE_FAILED:
    return {
      ...state,
      totalRevenue: {
        ...state.totalRevenue,
        isLoading: false,
        error: true
      }
    }

    // TOTAL TRAILING REVENUE
  case GET_TOTAL_TRAILING_REVENUE:
    return {
      ...state,
      totalTrailingRevenue: {
        ...state.totalTrailingRevenue,
        isLoading: true
      }
    }
  case GET_TOTAL_TRAILING_REVENUE_SUCCESS:
    return {
      ...state,
      totalTrailingRevenue: {
        ...state.totalTrailingRevenue,
        isLoading: false,
        error: false,
        value: payload.reduce((r, {value}) => r + value, 0)
      }
    }
  case GET_TOTAL_TRAILING_REVENUE_FAILED:
    return {
      ...state,
      totalTrailingRevenue: {
        ...state.totalTrailingRevenue,
        isLoading: false,
        error: true
      }
    }

    // CHART AUM
  case GET_CHART_AUM:
    return {
      ...state,
      chartAum: {
        ...state.chartAum,
        isLoading: true
      }
    }
  case GET_CHART_AUM_SUCCESS:
    return {
      ...state,
      chartAum: {
        ...state.chartAum,
        isLoading: false,
        error: false,
        data: payload
      }
    }
  case GET_CHART_AUM_FAILED:
    return {
      ...state,
      chartAum: {
        ...state.chartAum,
        isLoading: false,
        error: true
      }
    }

    // CHART REVENUE
  case GET_CHART_REVENUE:
    return {
      ...state,
      chartRevenue: {
        ...state.chartRevenue,
        isLoading: true
      }
    }
  case GET_CHART_REVENUE_SUCCESS:
    return {
      ...state,
      chartRevenue: {
        ...state.chartRevenue,
        isLoading: false,
        error: false,
        data: payload
      }
    }
  case GET_CHART_REVENUE_FAILED:
    return {
      ...state,
      chartRevenue: {
        ...state.chartRevenue,
        isLoading: false,
        error: true
      }
    }

  default:
    return state
  }
}
