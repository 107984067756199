import {
  REDEMPTION_ADD_TO_CART_REQUEST,
  REDEMPTION_ADD_TO_CART_FAILURE,
  REDEMPTION_ADD_TO_CART_SUCCESS,
  REDEMPTION_LIST_CART_REQUEST,
  REDEMPTION_LIST_CART_FAILURE,
  REDEMPTION_LIST_CART_SUCCESS,
  REDEMPTION_UPDATE_CART_REQUEST,
  REDEMPTION_UPDATE_CART_SUCCESS,
  REDEMPTION_UPDATE_CART_FAILURE,
  REDEMPTION_SEND_SMS_FAILURE,
  REDEMPTION_SEND_SMS_SUCCESS,
  REDEMPTION_SEND_SMS_REQUEST,
  REDEMPTION_SUBMIT_ORDER_FAILURE,
  REDEMPTION_SUBMIT_ORDER_SUCCESS,
  REDEMPTION_SUBMIT_ORDER_REQUEST
} from "../actionTypes/redemptions"

const initialState = {
  data: {
    redemptions: []
  },
  meta: {
    status: "",
    isLoading: true,
    isSubmit: false,
    isSendToken: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REDEMPTION_SUBMIT_ORDER_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isSubmit: true
        }
      }
    case REDEMPTION_SUBMIT_ORDER_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FETCHED",
          isSubmit: false
        }
      }
    case REDEMPTION_SUBMIT_ORDER_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FAILURE",
          isSubmit: false
        }
      }
    case REDEMPTION_SEND_SMS_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isSendToken: true
        }
      }
    case REDEMPTION_SEND_SMS_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FETCHED",
          isSendToken: false
        }
      }
    case REDEMPTION_SEND_SMS_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FAILURE",
          isSendToken: false
        }
      }
    case REDEMPTION_LIST_CART_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isLoading: true
        }
      }
    case REDEMPTION_LIST_CART_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          redemptions: action.payload
        },
        meta: {
          ...state.meta,
          status: "FETCHED",
          isLoading: false
        }
      }
    case REDEMPTION_LIST_CART_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FAILURE",
          isLoading: false
        }
      }
    case REDEMPTION_ADD_TO_CART_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isSubmit: true
        }
      }
    case REDEMPTION_ADD_TO_CART_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isSubmit: false
        }
      }
    case REDEMPTION_ADD_TO_CART_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FAILURE",
          isSubmit: false
        }
      }
    case REDEMPTION_UPDATE_CART_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isSubmit: true
        }
      }
    case REDEMPTION_UPDATE_CART_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isSubmit: false
        }
      }
    case REDEMPTION_UPDATE_CART_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FAILURE",
          isSubmit: false
        }
      }
    default:
      return state
  }
}
