import {
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_FILTER_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  SORTING_PRODUCT,
  GET_PRODUCT_DETAIL_SUCCESS
} from "../actionTypes/products"

const initialState = {
  data: {
    products: [],
    detail: {
      details: {}
    }
  },
  meta: {
    total: 0,
    status: "",
    isLoading: true,
    isLoadingFilter: false
  },
  sorter: {
    type: "ASC",
    sortBy: "name"
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isLoading: true
        }
      }
    case GET_PRODUCT_FILTER_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING_FILTER",
          isLoadingFilter: true
        }
      }
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          products: action.payload.products
        },
        meta: {
          ...state.meta,
          total: action.payload.total,
          status: "FETCHED",
          isLoading: false,
          isLoadingFilter: false
        }
      }
    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          detail: action.payload
        },
        meta: {
          ...state.meta,
          status: "FETCHED",
          isLoading: false
        }
      }
    case GET_PRODUCT_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FAILURE",
          isLoading: false
        }
      }
    case SORTING_PRODUCT:
      return {
        ...state,
        sorter: action.payload
      }
    default:
      return state
  }
}
