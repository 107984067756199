import {
  SAVE_INPUTS,
  START_FORM_REGISTER,
  START_FORM_EDIT,
  START_FORM_LOAD,
  ISLOAD_FORM_SUBMIT,
  SET_ID
} from "../actionTypes/form"

const initialState = {
  type: "",
  inputs: {},
  isLoading: true,
  isSubmitLoading: false,
  id: ""
}

export default (state = initialState, action) => {
  const {type, payload} = action

  switch (type) {
    case SET_ID:
      return {
        ...state,
        id: payload
      }
    case START_FORM_LOAD:
      return {
        ...state,
        isLoading: payload
      }
    case ISLOAD_FORM_SUBMIT:
      return {
        ...state,
        isSubmitLoading: payload
      }
    case SAVE_INPUTS:
      return {
        ...state,
        inputs: payload,
        isLoading: false
      }

    case START_FORM_REGISTER:
      return {
        ...state,
        type: "register",
        inputs: initialState.inputs,
        isLoading: false
      }
    case START_FORM_EDIT:
      return {
        ...state,
        type: "edit",
        isLoading: false,
        inputs: {
          ...state.inputs,
          ...payload
        }
      }
    default:
      return state
  }
}
