import {
  GET_NEW_ORDER_REQUEST,
  GET_NEW_ORDER_SUCCESS,
  GET_NEW_ORDER_FAILURE,
  SORTING_NEW_ORDER,
  SET_TEXT_FILTER_NEW_ORDER
} from "../actionTypes/orders"

const initialState = {
  data: {
    new_orders: [],
    outstanding_orders: [],
    complete_orders: []
  },
  meta: {
    status: "",
    isLoading: true,
    total: 0
  },
  filters: {
    new_orders: {
      text: ""
    }
  },
  sorter: {
    new_orders: {
      type: "DESC",
      sortBy: "order_date",
      dataType: "date"
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NEW_ORDER_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isLoading: true
        }
      }
    case GET_NEW_ORDER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          new_orders: action.payload.orders
        },
        meta: {
          ...state.meta,
          status: "FETCHED",
          isLoading: false,
          total: action.payload.total
        }
      }
    case GET_NEW_ORDER_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FAILURE",
          isLoading: false
        }
      }
    case SORTING_NEW_ORDER:
      return {
        ...state,
        sorter: {
          ...state.sorter,
          new_orders: action.payload
        }
      }
    case SET_TEXT_FILTER_NEW_ORDER:
      return {
        ...state,
        filters: {
          ...state.filters,
          new_orders: {
            ...state.filters.new_orders,
            text: action.payload
          }
        }
      }
    default:
      return state
  }
}
