import {
  GET_PORTFOLIO_AUM,
  GET_PORTFOLIO_AUM_SUCCESS,
  GET_PORTFOLIO_AUM_FAILED,
  GET_PORTFOLIO_LIST,
  GET_PORTFOLIO_LIST_SUCCESS,
  GET_PORTFOLIO_LIST_FAILED,
  GET_PORTFOLIO_TRANSACTION,
  GET_PORTFOLIO_TRANSACTION_SUCCESS,
  GET_PORTFOLIO_TRANSACTION_FAILED,
  GET_PORTFOLIO_DETAIL_REQUEST,
  GET_PORTFOLIO_DETAIL_SUCCESS,
  GET_PORTFOLIO_DETAIL_FAILED,
  SORTING_PORTFOLIO_LIST,
  SORTING_TRANSACTION,
  FILTER_BY_DATE,
  FILTER_BY_TEXT,
  FILTER_BY_TYPE,
  SET_TEXT_FILTER_PORTFOLIO_LIST,
  SET_FILTER_TRANSACTION
} from "../actionTypes/portfolio"

const initialState = {
  aum: {
    data: {},
    meta: {
      isLoading: false,
      error: false
    }
  },
  list: {
    data: [],
    detail: {},
    meta: {
      isLoading: false
    },
    sorter: {
      type: "ASC",
      sortBy: "name"
    },
    filters: {}
  },
  transactions: {
    data: [],
    meta: {
      isLoading: false,
      isLoadingFilter: false,
      status: ""
    },
    sorter: {
      type: "ASC",
      sortBy: "mor_order_date"
    },
    filters: {}
  }
}

export default (state = initialState, action) => {
  const {type, payload} = action

  switch (type) {
    case GET_PORTFOLIO_AUM:
      return {
        ...state,
        aum: {
          ...state.aum,
          meta: {
            ...state.aum.meta,
            isLoading: true,
            error: false
          }
        }
      }
    case GET_PORTFOLIO_AUM_SUCCESS:
      return {
        ...state,
        aum: {
          ...state.aum,
          data: payload,
          meta: {
            ...state.aum.meta,
            isLoading: false
          }
        }
      }
    case GET_PORTFOLIO_AUM_FAILED:
      return {
        ...state,
        aum: {
          ...state.aum,
          meta: {
            ...state.aum.meta,
            isLoading: false,
            error: true
          }
        }
      }
    case GET_PORTFOLIO_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            isLoading: true,
            error: false
          }
        }
      }
    case GET_PORTFOLIO_LIST_SUCCESS: {
      let data = payload.map(row => ({
        ...row,
        name: row.product.name,
        navValue: row.nav.value,
        navDate: row.nav.date,
        cif_code: row.client.cif_code
      }))

      return {
        ...state,
        list: {
          ...state.list,
          data,
          meta: {
            ...state.list.meta,
            isLoading: false
          }
        }
      }
    }
    case GET_PORTFOLIO_LIST_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            isLoading: false,
            error: true
          }
        }
      }

    case SORTING_PORTFOLIO_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          sorter: payload
        }
      }

    case SET_TEXT_FILTER_PORTFOLIO_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          filters: {
            ...state.list.filters,
            ...payload
          },
          sorter: initialState.list.sorter
        }
      }

    case SET_FILTER_TRANSACTION:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          filters: payload
        }
      }

    case GET_PORTFOLIO_DETAIL_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            isLoading: true
          }
        }
      }
    case GET_PORTFOLIO_DETAIL_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          detail: payload,
          meta: {
            ...state.list.meta,
            isLoading: false
          }
        }
      }
    case GET_PORTFOLIO_DETAIL_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            isLoading: false
          }
        }
      }
    case GET_PORTFOLIO_TRANSACTION:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          meta: {
            ...state.transactions.meta,
            isLoading: true,
            status: "LOADING"
          }
        }
      }
    case GET_PORTFOLIO_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          data: payload,
          meta: {
            ...state.transactions.meta,
            isLoading: false,
            status: "FETCHED"
          }
        }
      }
    case GET_PORTFOLIO_TRANSACTION_FAILED:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          meta: {
            ...state.transactions.meta,
            isLoading: false,
            status: "FAIL"
          }
        }
      }
    case SORTING_TRANSACTION:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          sorter: action.payload
        }
      }
    case FILTER_BY_TEXT:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          filters: {
            ...state.transactions.filters,
            text: payload
          }
        }
      }
    case FILTER_BY_DATE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          filters: {
            ...state.transactions.filters,
            startDate: payload.startDate,
            endDate: payload.endDate
          }
        }
      }
    case FILTER_BY_TYPE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          filters: {
            ...state.transactions.filters,
            instruction: payload
          }
        }
      }
    default:
      return state
  }
}
