export const REDEMPTION_ADD_TO_CART_REQUEST = "REDEMPTION_ADD_TO_CART_REQUEST"
export const REDEMPTION_ADD_TO_CART_SUCCESS = "REDEMPTION_ADD_TO_CART_SUCCESS"
export const REDEMPTION_ADD_TO_CART_FAILURE = "REDEMPTION_ADD_TO_CART_FAILURE"
export const REDEMPTION_UPDATE_CART_REQUEST = "REDEMPTION_UPDATE_CART_REQUEST"
export const REDEMPTION_UPDATE_CART_SUCCESS = "REDEMPTION_UPDATE_CART_SUCCESS"
export const REDEMPTION_UPDATE_CART_FAILURE = "REDEMPTION_UPDATE_CART_FAILURE"
export const REDEMPTION_LIST_CART_REQUEST = "REDEMPTION_LIST_CART_REQUEST"
export const REDEMPTION_LIST_CART_SUCCESS = "REDEMPTION_LIST_CART_SUCCESS"
export const REDEMPTION_LIST_CART_FAILURE = "REDEMPTION_LIST_CART_FAILURE"
export const REDEMPTION_SEND_SMS_REQUEST = "REDEMPTION_SEND_SMS_REQUEST"
export const REDEMPTION_SEND_SMS_SUCCESS = "REDEMPTION_SEND_SMS_SUCCESS"
export const REDEMPTION_SEND_SMS_FAILURE = "REDEMPTION_SEND_SMS_FAILURE"
export const REDEMPTION_SUBMIT_ORDER_FAILURE = "REDEMPTION_SUBMIT_ORDER_FAILURE"
export const REDEMPTION_SUBMIT_ORDER_SUCCESS = "REDEMPTION_SUBMIT_ORDER_SUCCESS"
export const REDEMPTION_SUBMIT_ORDER_REQUEST = "REDEMPTION_SUBMIT_ORDER_REQUEST"
export const REDEMPTION_SEND_OTP_REQUEST = "REDEMPTION_SEND_OTP_REQUEST"
export const REDEMPTION_SEND_OTP_SUCCESS = "REDEMPTION_SEND_OTP_SUCCESS"
export const REDEMPTION_SEND_OTP_FAILURE = "REDEMPTION_SEND_OTP_FAILURE"
