import {GET_PARENT_CODE_LIST} from "../actionTypes/parentCode"

const initialState = {
  listParentCode: {
    isLoading: true,
    error: false,
    data: []
  }
}

export default (state = initialState, action) => {
  const {type, payload} = action

  switch (type) {
    case GET_PARENT_CODE_LIST:
      return {
        ...state,
        data: payload
      }

    default:
      return state
  }
}
