// TOP INVESTORS
export const GET_TOP_INVESTORS = "GET_TOP_INVESTORS"
export const GET_TOP_INVESTORS_SUCCESS = "GET_TOP_INVESTORS_SUCCESS"
export const GET_TOP_INVESTORS_FAILED = "GET_TOP_INVESTORS_FAILED"

// TOTAL INVESTORS
export const GET_TOTAL_INVESTORS = "GET_TOTAL_INVESTORS"
export const GET_TOTAL_INVESTORS_SUCCESS = "GET_TOTAL_INVESTORS_SUCCESS"
export const GET_TOTAL_INVESTORS_FAILED = "GET_TOTAL_INVESTORS_FAILED"

// TOTAL AUM
export const GET_TOTAL_AUM = "GET_TOTAL_AUM"
export const GET_TOTAL_AUM_SUCCESS = "GET_TOTAL_AUM_SUCCESS"
export const GET_TOTAL_AUM_FAILED = "GET_TOTAL_AUM_FAILED"

// TOTAL REVENUE
export const GET_TOTAL_REVENUE = "GET_TOTAL_REVENUE"
export const GET_TOTAL_REVENUE_SUCCESS = "GET_TOTAL_REVENUE_SUCCESS"
export const GET_TOTAL_REVENUE_FAILED = "GET_TOTAL_REVENUE_FAILED"

// TOTAL TRAILING REVENUE
export const GET_TOTAL_TRAILING_REVENUE = "GET_TOTAL_TRAILING_REVENUE"
export const GET_TOTAL_TRAILING_REVENUE_SUCCESS =
  "GET_TOTAL_TRAILING_REVENUE_SUCCESS"
export const GET_TOTAL_TRAILING_REVENUE_FAILED =
  "GET_TOTAL_TRAILING_REVENUE_FAILED"

// CHART AUM
export const GET_CHART_AUM = "GET_CHART_AUM"
export const GET_CHART_AUM_SUCCESS = "GET_CHART_AUM_SUCCESS"
export const GET_CHART_AUM_FAILED = "GET_CHART_AUM_FAILED"

// CHART REVENUE
export const GET_CHART_REVENUE = "GET_CHART_REVENUE"
export const GET_CHART_REVENUE_SUCCESS = "GET_CHART_REVENUE_SUCCESS"
export const GET_CHART_REVENUE_FAILED = "GET_CHART_REVENUE_FAILED"
