export const SUBSCRIPTION_ADD_TO_CART_REQUEST =
  "SUBSCRIPTION_ADD_TO_CART_REQUEST"
export const SUBSCRIPTION_ADD_TO_CART_SUCCESS =
  "SUBSCRIPTION_ADD_TO_CART_SUCCESS"
export const SUBSCRIPTION_ADD_TO_CART_FAILURE =
  "SUBSCRIPTION_ADD_TO_CART_FAILURE"
export const SUBSCRIPTION_UPDATE_CART_REQUEST =
  "SUBSCRIPTION_UPDATE_CART_REQUEST"
export const SUBSCRIPTION_UPDATE_CART_SUCCESS =
  "SUBSCRIPTION_UPDATE_CART_SUCCESS"
export const SUBSCRIPTION_UPDATE_CART_FAILURE =
  "SUBSCRIPTION_UPDATE_CART_FAILURE"
export const SUBSCRIPTION_LIST_CART_REQUEST = "SUBSCRIPTION_LIST_CART_REQUEST"
export const SUBSCRIPTION_LIST_CART_SUCCESS = "SUBSCRIPTION_LIST_CART_SUCCESS"
export const SUBSCRIPTION_LIST_CART_FAILURE = "SUBSCRIPTION_LIST_CART_FAILURE"
export const SUBSCRIPTION_SEND_SMS_REQUEST = "SUBSCRIPTION_SEND_SMS_REQUEST"
export const SUBSCRIPTION_SEND_SMS_SUCCESS = "SUBSCRIPTION_SEND_SMS_SUCCESS"
export const SUBSCRIPTION_SEND_SMS_FAILURE = "SUBSCRIPTION_SEND_SMS_FAILURE"
export const SUBSCRIPTION_SEND_OTP_REQUEST = "SUBSCRIPTION_SEND_OTP_REQUEST"
export const SUBSCRIPTION_SEND_OTP_SUCCESS = "SUBSCRIPTION_SEND_OTP_SUCCESS"
export const SUBSCRIPTION_SEND_OTP_FAILURE = "SUBSCRIPTION_SEND_OTP_FAILURE"
export const SUBSCRIPTION_SUBMIT_ORDER_FAILURE =
  "SUBSCRIPTION_SUBMIT_ORDER_FAILURE"
export const SUBSCRIPTION_SUBMIT_ORDER_SUCCESS =
  "SUBSCRIPTION_SUBMIT_ORDER_SUCCESS"
export const SUBSCRIPTION_SUBMIT_ORDER_REQUEST =
  "SUBSCRIPTION_SUBMIT_ORDER_REQUEST"
