import {
  GET_DETAIL_INVESTOR_REQUEST,
  GET_DETAIL_INVESTOR_SUCCESS,
  GET_DETAIL_INVESTOR_FAILURE,
  GET_DETAIL_STORAGE_REQUEST,
  GET_DETAIL_STORAGE_SUCCESS,
  GET_DETAIL_STORAGE_FAILURE
} from "../actionTypes/signature"

const initialState = {
  data: {
    detail: {},
    storage: []
  },
  meta: {
    status: "LOADING",
    isLoading: false,
    isStorage: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DETAIL_INVESTOR_REQUEST:
      return {
        ...state,
        meta: {
          status: "LOADING",
          isLoading: true
        }
      }
    case GET_DETAIL_INVESTOR_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          detail: action.payload
        },
        meta: {
          status: "FETCHED",
          isLoading: false
        }
      }
    case GET_DETAIL_INVESTOR_FAILURE:
      return {
        ...state,
        meta: {
          status: "FAILURE",
          isLoading: false
        }
      }
    case GET_DETAIL_STORAGE_REQUEST:
      return {
        ...state,
        meta: {
          status: "LOADING",
          isStorage: true
        }
      }
    case GET_DETAIL_STORAGE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          storage: action.payload
        },
        meta: {
          status: "FETCHED",
          isStorage: false
        }
      }
    case GET_DETAIL_STORAGE_FAILURE:
      return {
        ...state,
        meta: {
          status: "FAILURE",
          isStorage: false
        }
      }
    default:
      return state
  }
}
