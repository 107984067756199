import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT
} from "../actionTypes/auth"

const initialState = {
  auth: {
    data: {
      isLogin: false,
      token: null
    },
    meta: {
      isLoading: false,
      status: ""
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        auth: {
          ...state.auth,
          meta: {
            isLoading: true,
            status: "LOADING"
          }
        }
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        auth: {
          ...state.auth,
          data: {
            ...state.auth.data,
            isLogin: true,
            token: action.payload.token
          },
          meta: {
            isLoading: false,
            status: "FETCHED"
          }
        }
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        auth: {
          ...state.auth,
          meta: {
            isLoading: false,
            status: "FAILURE"
          }
        }
      }
    case LOGOUT:
      return {
        ...state,
        auth: {
          ...state.auth,
          data: {
            ...state.auth.data,
            isLogin: false,
            token: null
          }
        }
      }
    default:
      return state
  }
}
