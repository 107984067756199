import {
  GET_SUBADVISOR_REQUEST,
  GET_SUBADVISOR_SUCCESS,
  GET_SUBADVISOR_FAILURE
} from "../actionTypes/subadvisors"

const initialState = {
  data: {
    subadvisors: []
  },
  meta: {
    total: 0,
    status: "",
    isLoading: true
  },
  sorter: {
    subadvisors: {
      type: "DESC",
      sortBy: "created_at",
      dataType: "date"
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBADVISOR_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isLoading: true
        }
      }
    case GET_SUBADVISOR_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          subadvisors: action.payload.agents
        },
        meta: {
          total: action.payload.total,
          status: "FETCHED",
          isLoading: false
        }
      }
    case GET_SUBADVISOR_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FAILURE",
          isLoading: false
        }
      }
    default:
      return state
  }
}
