import {SET_TEXT_FILTER_SUBADVISOR} from "../actionTypes/filterSubadvisors"

const filterReducersDefaultState = {
  text: ""
}

export default (state = filterReducersDefaultState, action) => {
  switch (action.type) {
    case SET_TEXT_FILTER_SUBADVISOR:
      return {
        ...state,
        text: action.payload
      }
    default:
      return state
  }
}
