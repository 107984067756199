import {
  GET_OUTSTANDING_ORDER_REQUEST,
  GET_OUTSTANDING_ORDER_SUCCESS,
  GET_OUTSTANDING_ORDER_FAILURE,
  SORTING_OUTSTANDING_ORDER
  // SET_TEXT_FILTER_OUTSTANDING_ORDER
} from "../actionTypes/oustandingOrders"

const initialState = {
  data: [],
  meta: {
    status: "",
    isLoading: true,
    total: 0
  },
  filters: {},
  sorter: {
    type: "DESC",
    sortBy: "created_at",
    dataType: "date"
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OUTSTANDING_ORDER_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "LOADING",
          isLoading: true
        }
      }
    case GET_OUTSTANDING_ORDER_SUCCESS:
      return {
        ...state,
        data: action.payload.orders,
        meta: {
          ...state.meta,
          status: "FETCHED",
          isLoading: false,
          total: action.payload.total
        }
      }
    case GET_OUTSTANDING_ORDER_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: "FAILURE",
          isLoading: false
        }
      }
    case SORTING_OUTSTANDING_ORDER:
      return {
        ...state,
        sorter: action.payload
      }
    // case SET_TEXT_FILTER_OUTSTANDING_ORDER:
    //   return {
    //     ...state,
    //     filters: {
    //       ...state.filters,
    //       ...action.payload
    //     }
    //   }
    default:
      return state
  }
}
